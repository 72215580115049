.schedules__container {
  position: relative;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex; */
  padding: 32px 32px 32px 16px;
  border-radius: 24px;
  background: #ffffff;
}

.schedules__container:before {
  content: '';
  position: absolute;
  top: 35px;
  left: 35px;
  right: 35px;
  bottom: -35px;
  z-index: -5;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.schedules__col:first-child {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 310px;
}

.schedules__col:first-child .schedules__title {
  display: none;
}

.schedules__col:nth-child(2) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 16px;
}

.schedules__list {
  margin-top: -8px;
}

.schedules__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 12px;
  background: transparent;
  color: #11142d;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.schedules__item:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 10px;
  right: 10px;
  height: 6px;
  border-radius: 0 0 6px 6px;
  background: #cfc8ff;
  opacity: 0;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.schedules__item:hover {
  color: #6c5dd3;
}

.schedules__item:hover .icon {
  fill: #6c5dd3;
}

.schedules__item.active {
  background: #6c5dd3;
  color: #ffffff;
}

.schedules__item.active:before {
  opacity: 1;
}

.schedules__item:not(:last-child) {
  margin-bottom: 24px;
}

.schedules__date {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 88px;
  height: 80px;
  margin-right: 24px;
  padding-top: 7px;
  border-radius: 12px;
  /* padding: 10px; */
}

/*
.schedules__date:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 9px;
  right: 9px;
  height: 7px;
  border-radius: 0 0 10px 10px;
}
*/

.schedules__date.bg-pink-light:before {
  background: rgba(255, 235, 246, 0.5);
}

.schedules__date.bg-purple-light:before {
  background: rgba(207, 200, 255, 0.5);
}

.schedules__date.bg-blue-light:before {
  background: rgba(160, 215, 231, 0.5);
}

.schedules__date.bg-yellow-light:before {
  background: rgba(255, 226, 172, 0.5);
}

.schedules__name {
  line-height: 1;
  color: #808191;
}

.schedules__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.schedules__category {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
  padding-right: 35px;
}

.schedules__category .icon {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 10px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.schedules__info {
  margin-bottom: 8px;
  color: #808191;
}

.schedules__time {
  display: inline-block;
  padding: 3px 10px;
  background: #6c5dd3;
  border-radius: 8px;
  color: #ffffff;
}

.schedules__item.active .schedules__name,
.schedules__item.active .schedules__info {
  color: #ffffff;
}

.schedules__item.active .schedules__category .icon {
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
  fill: #ffffff;
}

.schedules__item.active .schedules__date {
  background: transparent;
}

.schedules__item.active .schedules__date:before {
  background: transparent;
}

.schedules__item.active .schedules__time {
  background: #ffebf6;
  color: #11142d;
}

.schedules__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.schedules__arrows {
  display: none;
  margin-left: auto;
  margin-right: -16px;
}

.schedules__arrow {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  background: #ffffff;
  font-size: 0;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;
}

.schedules__arrow .icon {
  font-size: 10px;
  fill: #11142d;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.schedules__arrow:hover {
  background: #6c5dd3;
}

.schedules__arrow:hover .icon {
  fill: #ffffff;
}

.schedules__arrow:not(:last-child) {
  margin-right: 8px;
}

.schedules__col > .schedules__title {
  margin-bottom: 32px;
}

.schedules__btn {
  display: none;
}

.schedules__graphic {
  position: relative;
  max-width: 586px;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}

.schedules__graphic:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 14px;
  right: 14px;
  height: 7px;
  border-radius: 0 0 7px 7px;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #e4e4e4;
}

.schedules__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 68px;
  border-bottom: 1px solid #e4e4e4;
}

.schedules__option {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 138px;
  padding-left: 32px;
  font-size: 13px;
  font-weight: 600;
  color: #808191;
}

.schedules__week {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.schedules__cell {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666%;
  flex: 0 0 16.666%;
  width: 16.666%;
}

.schedules__day {
  position: relative;
  display: inline-block;
  min-width: 32px;
  margin-left: -16px;
  background: transparent;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
}

.schedules__day:before {
  content: '';
  position: absolute;
  top: 6px;
  right: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.schedules__day.active {
  background: #ff754c;
  color: #ffffff;
}

.schedules__day.green:before {
  background: #7fba7a;
}

.schedules__day.purple:before {
  background: #6c5dd3;
}

.schedules__day.gray:before {
  background: #e4e4e4;
}

.schedules__body {
  position: relative;
  padding: 26px 0 22px;
}

.schedules__grid {
  position: absolute;
  top: 0;
  left: 138px;
  right: 0;
  bottom: 0;
}

.schedules__line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #e4e4e4;
  pointer-events: none;
}

.schedules__line:first-child {
  left: 8.33%;
}

.schedules__line:nth-child(2) {
  left: 25%;
}

.schedules__line:nth-child(3) {
  left: 41.66%;
}

.schedules__line:nth-child(4) {
  left: 58.33%;
}

.schedules__line:nth-child(5) {
  left: 75%;
}

.schedules__row {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.schedules__row:not(:last-child) {
  margin-bottom: 18px;
}

.schedules__task {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  padding: 0 2px;
  border-radius: 8px;
}

.schedules__task .schedules__text {
  padding: 0 15px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
}

.schedules__legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  padding: 0 16px;
}

.schedules__status {
  position: relative;
  padding-left: 20px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  color: #808191;
}

.schedules__status:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.schedules__status.green:before {
  background: #7fba7a;
}

.schedules__status.purple:before {
  background: #6c5dd3;
}

.schedules__status.gray:before {
  background: #e4e4e4;
}

.schedules__status:not(:last-child) {
  margin-right: 120px;
}

.schedules:not(:last-child) {
  margin-bottom: 36px;
}

body.dark .schedules__container {
  background: #242731;
}

body.dark .schedules__container:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .schedules__item {
  color: #ffffff;
}

body.dark .schedules__category .icon {
  fill: #ffffff;
}

body.dark .schedules__name,
body.dark .schedules__number {
  color: #5f75ee;
}

body.dark .schedules__item:hover {
  color: #6c5dd3;
}

body.dark .schedules__item:hover .icon {
  fill: #6c5dd3;
}

body.dark .schedules__item.active,
body.dark .schedules__item.active .schedules__number {
  color: #ffffff;
}

body.dark .schedules__item.active .icon {
  fill: #ffffff;
}

body.dark .schedules__graphic {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__graphic:before {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__head {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__day.gray:before,
body.dark .schedules__status.gray:before {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__line {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__task .schedules__text {
  background: rgba(36, 39, 49, 0.8);
}

body.dark .schedules__arrow {
  background: #242731;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
}

body.dark .schedules__arrow .icon {
  fill: #ffffff;
}

@media (max-width: 600px) {
  .outer_weekly__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
  .weekly__container {
    flex-direction: 'row';
    justify-content: space-around;
    width: 100%;
    background-color: #eee;
    display: flex;
    padding: 15px;
    border-radius: 12px;
  }
}

@media (min-width: 576px) {
  .outer_weekly__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
  .weekly__container {
    flex-direction: 'row';
    justify-content: space-around;
    width: 100%;
    background-color: #eee;
    display: flex;
    padding: 15px;
    border-radius: 12px;
  }
}

@media (min-width: 768px) {
  .outer_weekly__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
  .weekly__container {
    flex-direction: 'row';
    justify-content: space-around;
    width: 100%;
    background-color: #eee;
    display: flex;
    padding: 15px;
    border-radius: 12px;
  }
}

@media (min-width: 992px) {
  .outer_weekly__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  .weekly__container {
    flex-direction: 'row';
    justify-content: 'space-between';
    width: 100%;
  }
}

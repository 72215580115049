body {
  background-color: #eceaea;
}

.title {
  font-size: 16px;
  line-height: 1.1875;
  font-weight: 600;
}

.title_lg,
.title_md,
.title_sm {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
}

.title_lg {
  font-size: 96px;
  line-height: 1.25;
}

.title_md {
  font-size: 72px;
  line-height: 1.22222;
}

.title_sm {
  font-size: 64px;
  line-height: 1.125;
}

.dropdown-item:hover {
  cursor: pointer;
}

h2 {
  padding-left: 15px;
}

.grill-label {
  font-weight: bold;
}

.rec-list {
  border-bottom: #ccc 1px solid !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-label {
  font-size: 10pt;
  font-weight: 400;
}

.form-error {
  color: #e55353;
  font-size: 65%;
  margin-top: 0.25rem;
}

.form-control::placeholder {
  opacity: 0.5;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.5;
}

.data-section {
  padding-top: 20px;
}

.align-right {
  text-align: right;
  margin-right: 1em;
}

tr.highlight {
  background-color: #5ccc7a;
}

tr.highlight > td,
tr.highlight:hover,
tr.highlight td h6 {
  color: #fff !important;
  font-weight: bold;
}

.table-header {
  display: none;
}

.print-page-wrapper {
  display: none;
}

body > .print-page-wrapper {
  display: block;
}

.print-page-wrapper img.logo {
  display: inline-block !important;
}

.btn-secondary:hover {
  color: #fff !important;
}

@media print {
  div.calculations-wrapper > table > tbody > tr > td {
    color: #3c4b64;
  }

  .print-page-wrapper {
    display: block !important;
    margin-top: 40px !important;
  }

  .print-page-wrapper img.logo {
    display: inline-block !important;
  }

  /* .table::after {
		content: "UpDog ROI Calculator by Inteladata";
	} */

  .table-header {
    font-size: 30pt;
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  tr.highlight > td,
  tr.highlight:hover,
  tr.highlight td h6 {
    background-color: #5ccc7a !important;
    color: white !important;
    font-weight: bold;
  }
}

td {
  font-size: 9pt;
}

.table > :not(caption) > * > * {
  padding: 1em;
}

.col-6,
.col-12 {
  margin-bottom: 20px;
}

.footer-text {
  font-size: 9pt;
}

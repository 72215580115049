.details__container {
  position: relative;
  padding: 32px 32px 38px;
  background: #ffffff;
  border-radius: 24px;
}

.details__container:before {
  content: '';
  position: absolute;
  top: 28px;
  left: 18px;
  right: 18px;
  bottom: -28px;
  z-index: -1;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.details__title {
  margin-bottom: 40px;
}

.details__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.details__col:first-child {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 145px;
  padding-right: 16px;
}

.details__col:nth-child(2) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.details__top {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e4e4;
}

.details__number {
  margin-bottom: 16px;
}

.details__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5f75ee;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.details__line:hover {
  color: #6c5dd3;
}

.details__preview {
  font-size: 0;
}

.details__line .details__preview {
  width: 32px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 16px;
}

.details__statistics {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.details__status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.details__status .details__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
  font-size: 0;
}

.details__status .details__icon .icon {
  font-size: 6px;
  fill: #ffffff;
}

.details__box {
  position: relative;
  padding: 5px 5px 13px;
  border-radius: 16px;
  background: #3f8cff;
}

.details__chart_activity {
  height: 25px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 13px;
}

.details__chart_counter {
  height: 200px;
}

.details__chart_counter .apexcharts-tooltip.apexcharts-theme-light {
  background: #1b1d21;
  border: none;
  color: #ffffff;
}

.details__chart_report {
  height: 215px;
}

.details__info {
  color: #b2b3bd;
}

.details__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}

.details__item {
  padding: 24px;
}

.details__item:not(:last-child) {
  border-right: 1px solid #e4e4e4;
}

.details__list_three .details__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  width: 33.333%;
}

.details__list_four .details__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  width: 25%;
}

.details__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

.details__head .details__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 6px;
  font-size: 0;
}

.details__head .details__pic {
  max-width: 8px;
}

.details__indicator {
  position: relative;
  height: 2px;
  border-radius: 1px;
  background: #e4e4e4;
}

.details__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.details__remove {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

.details__remove .icon {
  font-size: 10px;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.details__remove:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.details .dropdown {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 200px;
}

.details:not(:last-child) {
  margin-bottom: 28px;
}

.details_statements:not(:last-child) {
  margin-bottom: 68px;
}

.details_statements .details__col:first-child {
  width: 170px;
  padding-right: 25px;
}

.details_statements .details__counter {
  margin-bottom: 10px;
}

.details_statements .details__line .details__info {
  color: #5f75ee;
}

body.dark .details__container {
  background: #242731;
}

body.dark .details__container:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.details__head .details__pic {
  max-width: 8px;
}
